<script>
	import SearchAV1 from '~/assets/video/how-it-works/1-search/search.4k.av1.mp4?url';
	import SearchHEVC from '~/assets/video/how-it-works/1-search/search.4k.hevc.mp4?url';
	import SearchH264 from '~/assets/video/how-it-works/1-search/search.4k.h264.mp4?url';
	import TrackYourBusAV1 from '~/assets/video/how-it-works/2-track-your-bus/track-your-bus.4k.av1.mp4?url';
	import TrackYourBusHEVC from '~/assets/video/how-it-works/2-track-your-bus/track-your-bus.4k.hevc.mp4?url';
	import TrackYourBusH264 from '~/assets/video/how-it-works/2-track-your-bus/track-your-bus.4k.h264.mp4?url';
	import RescheduleEasilyAV1 from '~/assets/video/how-it-works/3-reschedule-easily/reschedule-easily.4k.av1.mp4?url';
	import RescheduleEasilyHEVC from '~/assets/video/how-it-works/3-reschedule-easily/reschedule-easily.4k.hevc.mp4?url';
	import RescheduleEasilyH264 from '~/assets/video/how-it-works/3-reschedule-easily/reschedule-easily.4k.h264.mp4?url';
	import BookFlexiPassAV1 from '~/assets/video/how-it-works/4-book-flexi-pass/book-flexi-pass.4k.av1.mp4?url';
	import BookFlexiPassHEVC from '~/assets/video/how-it-works/4-book-flexi-pass/book-flexi-pass.4k.hevc.mp4?url';
	import BookFlexiPassH264 from '~/assets/video/how-it-works/4-book-flexi-pass/book-flexi-pass.4k.h264.mp4?url';

	import SelectYourRideIcon from '~/icons/HowItWorks/search.svelte';
	import TrackYourBusIcon from '~/icons/HowItWorks/track-your-bus.svelte';
	import RescheduleEasilyIcon from '~/icons/HowItWorks/reschedule-easily.svelte';
	import BookFlexiPassIcon from '~/icons/HowItWorks/book-flexi-pass.svelte';

	import IphoneMockup from '~/components/atoms/HowItWorksIphoneMockup.svelte';
	import { isMobile, videoInView } from '~/utils/store';
	import { onMount } from 'svelte';

	let activeIndex = 0;

	export let sections = [
		{
			header: 'Select your ride',
			content:
				'Book your free ride by selecting your pickup, dropoff & preferred seat.',
			Icon: SelectYourRideIcon,
			av1: SearchAV1,
			hevc: SearchHEVC,
			h264: SearchH264,
		},
		{
			header: 'Track your bus',
			content: 'Live track your bus location, stop details and get access to safety features.',
			Icon: TrackYourBusIcon,
			av1: TrackYourBusAV1,
			hevc: TrackYourBusHEVC,
			h264: TrackYourBusH264,
		},
		{
			header: 'Reschedule easily',
			content:
				'Change of plans? Simply reschedule your ride or cancel it.',
			Icon: RescheduleEasilyIcon,
			av1: RescheduleEasilyAV1,
			hevc: RescheduleEasilyHEVC,
			h264: RescheduleEasilyH264,
		},
		{
			header: 'Book flexi pass',
			content:
				'Enjoy up to 30% savings, free cancellations and rescheduling.',
			Icon: BookFlexiPassIcon,
			av1: BookFlexiPassAV1,
			hevc: BookFlexiPassHEVC,
			h264: BookFlexiPassH264,
		},
	];

	const resetVideoTimer = () => {
		document.querySelector('.how-it-works-layout').setAttribute('style', '--video-percent: 0%');
	};

	const scrollVideoIntoViewAtIndex = (index) => {
		document
			.querySelector(`[data-slider-mockup-index="${index}"]`)
			.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
	};

	const onSliderDotClick = (e) => {
		const sliderIndex = Number(e.target.closest('[data-slider-index]').dataset.sliderIndex);
		if (activeIndex === sliderIndex) {
			return;
		}
		resetVideoTimer();
		activeIndex = sliderIndex;
		scrollVideoIntoViewAtIndex(activeIndex);
	};

	const nextVideo = () => {
		resetVideoTimer();
		activeIndex = (activeIndex + 1) % sections.length;
		scrollVideoIntoViewAtIndex(activeIndex);
	};

	onMount(() => {
		let timeout = {};
		let intersectionEventTime = 0;
		let mockupScrollOptions;
		let mockups;
		let mockupScrollObserver;

		const playPauseOptions = {
			rootMargin: '0px 0px 0px 0px',
			threshold: 0.5,
		};
		const target = document.querySelector('#mockup-container');
		const playPauseObserver = new IntersectionObserver((entries) => {
			entries.forEach((entry, index) => {
				$videoInView = entry.isIntersecting;
			});
		}, playPauseOptions);

		playPauseObserver.observe(target);
		
		if ($isMobile) {
			mockupScrollOptions = {
				rootMargin: '0px 0px 0px 0px',
				threshold: 1,
			};

			mockups = document.querySelectorAll('.z-10.relative.w-fit.m-auto');

			mockupScrollObserver = new IntersectionObserver((entries) => {
				const intersectingEntries = entries.filter((entry) => entry.isIntersecting);

				if (intersectingEntries.length === 1) {
					clearTimeout(timeout);
					const entry = intersectingEntries[0];
					const dataIndex = Number(entry.target.dataset.sliderIndex);

					if (dataIndex === activeIndex) {
						return;
					}

					if (entry.time > intersectionEventTime) {
						intersectionEventTime = entry.time;
						timeout = setTimeout(() => {
							activeIndex = dataIndex;
							resetVideoTimer();
						}, 200);
					}
				}

			}, mockupScrollOptions);

			mockups.forEach((mockup) => {
				mockupScrollObserver.observe(mockup);
			});

		}

		return () => {
			playPauseObserver.unobserve(target);

			if ($isMobile) {
				mockups.forEach((target) => {
					mockupScrollObserver.unobserve(target);
				});
			}
		};
	});

</script>

<section
	id="how-it-works"
	class="how-it-works-layout bg-complementary-grey pt-16 md:pt-40 px-8 md:px-36 overflow-hidden md:bg-[url('../../assets/images/home/how-it-works-pattern.png')] bg-repeat-x bg-bottom"
>
	<h2 class="how-it-works-header tracking-normal heading text-primary mb-5 md:mb-0 h-fit">How it works</h2>
	<p class="how-it-works-sub-title content mb-6 md:mb-24 md:-mt-0 h-fit text-primary hidden md:block">
		It’s simple to take a ride. Just search, select and it’s done!
	</p>
	<div
		id="mockup-container"
		class="how-it-works-mockup hide-scroll-bar grid grid-cols-[repeat(5,100%)] grid-rows-[auto_auto] gap-y-1 md:gap-[120px] md:justify-items-center overflow-x-auto md:overflow-x-hidden snap-x snap-mandatory pl-[calc(100%-155px)] md:pl-[calc(100%-332px)] -mx-8"
	>
		{#each sections as section, index}
			{@const active = index === activeIndex}
			<div
				data-slider-mockup-index={index}
				class="snap-center snap-always grid justify-center grid-rows-[auto_auto] gap-y-14"
			>
				<IphoneMockup
					class="w-[252px] lg:w-[300px]"
					data={section}
					{active}
					{nextVideo}
				/>
				{#if $isMobile}
					<div data-slider-index={index} class="z-10 relative w-fit m-auto" on:click={onSliderDotClick}>
						{#if active}
							<div class="absolute w-full h-full top-1 rounded-3xl -z-10" class:timer-bg={active}></div>
						{/if}
						<div
							class="inline-flex items-center rounded-3xl justify-center gap-4 py-[10px] h-[42px] px-5 cursor-pointer"
							class:bg-primary={active}
							class:bg-neutral-0={!active}
						>
							<svelte:component
								this={section.Icon}
								class={`${active ? 'w-6 text-neutral-0' : 'w-4 text-primary'} transition-all duration-300`}
							/>
							<span
								class={`${active ? 'text-neutral-0 text-lg font-bold' : 'text-primary text-base font-medium'}`}
								>{section.header}</span
							>
						</div>
					</div>
				{/if}
			</div>
		{/each}
	</div>
	{#if !$isMobile}
		<div class="how-it-works-mockup relative inline">
			<div class="absolute flex gap-3 left-[calc(25vw-136px)] top-[555px] lg:top-[654px] z-10">
				{#each sections as _, index}
					{@const active = index === activeIndex}
					<span
						data-slider-index={index}
						class="block h-4 rounded-full transition-all duration-300 cursor-pointer overflow-hidden bg-neutral-200"
						class:w-10={active}
						class:w-4={!active}
						on:click={onSliderDotClick}
					>
						{#if active}
							<div class="w-full h-full" class:timer-bg={active}></div>
						{/if}
					</span>
				{/each}
			</div>
		</div>
	{/if}
	<div class="how-it-works-content grid relative">
		{#if $isMobile}
			<div class="absolute flex gap-3 left-[calc(50vw-94px)] top-[-85px] z-10">
				{#each sections as _, index}
					{@const active = index === activeIndex}
					<span
						data-slider-index={index}
						class="block h-4 rounded-full transition-all duration-300 cursor-pointer overflow-hidden bg-neutral-200"
						class:w-10={active}
						class:w-4={!active}
						on:click={onSliderDotClick}
					>
						{#if active}
							<div class="w-full h-full" class:timer-bg={active}></div>
						{/if}
					</span>
				{/each}
			</div>
			<div
				class="rounded-[20px] pt-[54px] pb-8 px-6 bg-white bg-[url('../../assets/images/home/how-it-works-pattern.png')] bg-no-repeat bg-bottom -translate-y-8"
			>
				<p class="text-primary-100 text-base text-center">{@html sections[activeIndex].content}</p>
			</div>
		{:else}
			{#each sections as section, index}
				{@const active = index === activeIndex}
				<!-- {@const borderB = !active && activeIndex < index} -->
				<!-- {@const borderT = !active && index < activeIndex} -->
				<div
					data-slider-index={index}
					class={`z-10 relative h-fit cursor-pointer border-neutral-200`}
					on:click={onSliderDotClick}
				>
					{#if active}
						<div class="absolute w-full h-full top-1 rounded-3xl -z-10" class:timer-bg={active}></div>
					{/if}
					<div
						class={`grid grid-cols-[auto_1fr] gap-4 px-10 py-8 ${active ? 'border-accent-800 border bg-white rounded-3xl' : ''} transition-colors duration-150`}
					>
						<svelte:component this={section.Icon} class="w-8 text-accent-800" />
						<div>
							<h3 class="text-primary text-2xl font-bold">{section.header}</h3>
							{#if active}
								<p class="mt-5 text-primary-100">{@html section.content}</p>
							{/if}
						</div>
					</div>
				</div>
			{/each}
		{/if}
	</div>
</section>

<style>
	.how-it-works-layout {
		display: grid;
		grid-template-areas: 'header' 'sub-title' 'mockup' 'content';
		grid-template-rows: auto auto auto auto;
		grid-template-columns: 1fr;
		--video-percent: 0%;
	}

	.timer-bg {
		background: linear-gradient(90deg, #e3aa31 50%, transparent 50%);
		background-size: 205% 100%;
		background-position-x: calc(100% - var(--video-percent));
		transition: background-position 300ms linear 0s;
	}

	@media only screen and (min-width: 768px) {
		.how-it-works-layout {
			grid-template-areas: 'header mockup' 'sub-title mockup' 'content mockup' 'content mockup' 'content mockup' 'content mockup' 'content mockup' 'content mockup' 'content mockup' 'content mockup' 'content mockup' 'content mockup';
			grid-template-rows: repeat(16, min-content);
			grid-template-columns: 1fr 1fr;
		}

		.how-it-works-content {
			grid-template-rows: min-content min-content min-content min-content;
			max-width: 634px;
		}
	}

	.how-it-works-header {
		grid-area: header;
	}

	.how-it-works-content {
		grid-area: content;
	}

	.how-it-works-sub-title {
		grid-area: sub-title;
	}

	.how-it-works-mockup {
		grid-area: mockup;
	}
</style>
